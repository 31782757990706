/* tslint:disable */
/* eslint-disable */
/**
 * DevSpace API
 * API for DevSpace
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AuthLoginPost200Response
 */
export interface AuthLoginPost200Response {
    /**
     * 
     * @type {string}
     * @memberof AuthLoginPost200Response
     */
    'status'?: string;
    /**
     * The JWT token for the user
     * @type {string}
     * @memberof AuthLoginPost200Response
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface AuthLoginPostRequest
 */
export interface AuthLoginPostRequest {
    /**
     * The email of the user
     * @type {string}
     * @memberof AuthLoginPostRequest
     */
    'email'?: string;
    /**
     * The password of the user
     * @type {string}
     * @memberof AuthLoginPostRequest
     */
    'password'?: string;
    /**
     * Whether to keep the user logged in for a long time
     * @type {boolean}
     * @memberof AuthLoginPostRequest
     */
    'longExpiration'?: boolean;
}
/**
 * 
 * @export
 * @interface AuthLogoutGet200Response
 */
export interface AuthLogoutGet200Response {
    /**
     * 
     * @type {string}
     * @memberof AuthLogoutGet200Response
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface AuthSignupPostRequest
 */
export interface AuthSignupPostRequest {
    /**
     * The email of the user
     * @type {string}
     * @memberof AuthSignupPostRequest
     */
    'email'?: string;
    /**
     * The password of the user
     * @type {string}
     * @memberof AuthSignupPostRequest
     */
    'password'?: string;
    /**
     * Password validation field
     * @type {string}
     * @memberof AuthSignupPostRequest
     */
    'passwordValidation'?: string;
    /**
     * The first name of the user
     * @type {string}
     * @memberof AuthSignupPostRequest
     */
    'firstName'?: string;
    /**
     * The last name of the user
     * @type {string}
     * @memberof AuthSignupPostRequest
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * Comment ID
     * @type {number}
     * @memberof Comment
     */
    'id'?: number;
    /**
     * Comment content
     * @type {string}
     * @memberof Comment
     */
    'content'?: string;
    /**
     * Comment creation date
     * @type {string}
     * @memberof Comment
     */
    'createdAt'?: string;
    /**
     * 
     * @type {User}
     * @memberof Comment
     */
    'createdBy'?: User;
    /**
     * 
     * @type {Array<User>}
     * @memberof Comment
     */
    'likedBy'?: Array<User>;
}
/**
 * 
 * @export
 * @interface CommentPostRequest
 */
export interface CommentPostRequest {
    /**
     * Comment content
     * @type {string}
     * @memberof CommentPostRequest
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface CreatePostRequest
 */
export interface CreatePostRequest {
    /**
     * Post title
     * @type {string}
     * @memberof CreatePostRequest
     */
    'title'?: string;
    /**
     * Post content
     * @type {string}
     * @memberof CreatePostRequest
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * Notification ID
     * @type {number}
     * @memberof Notification
     */
    'id'?: number;
    /**
     * Notification message
     * @type {string}
     * @memberof Notification
     */
    'message'?: string;
    /**
     * Notification creation date
     * @type {string}
     * @memberof Notification
     */
    'timeStamp'?: string;
    /**
     * Notification seen status
     * @type {boolean}
     * @memberof Notification
     */
    'seen'?: boolean;
}
/**
 * 
 * @export
 * @interface Post
 */
export interface Post {
    /**
     * Post ID
     * @type {number}
     * @memberof Post
     */
    'id'?: number;
    /**
     * Post title
     * @type {string}
     * @memberof Post
     */
    'title'?: string;
    /**
     * Post content
     * @type {string}
     * @memberof Post
     */
    'content'?: string;
    /**
     * Post creation date
     * @type {string}
     * @memberof Post
     */
    'createdAt'?: string;
    /**
     * 
     * @type {User}
     * @memberof Post
     */
    'createdBy'?: User;
    /**
     * 
     * @type {Array<User>}
     * @memberof Post
     */
    'likedBy'?: Array<User>;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof Post
     */
    'comments'?: Array<Comment>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * User ID
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * User first name
     * @type {string}
     * @memberof User
     */
    'firstName'?: string;
    /**
     * User private status
     * @type {boolean}
     * @memberof User
     */
    'isPrivate'?: boolean;
    /**
     * User profile picture ID
     * @type {string}
     * @memberof User
     */
    'profilePictureId'?: string;
    /**
     * User last name
     * @type {string}
     * @memberof User
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface UserWithRelations
 */
export interface UserWithRelations {
    /**
     * User ID
     * @type {number}
     * @memberof UserWithRelations
     */
    'id'?: number;
    /**
     * User first name
     * @type {string}
     * @memberof UserWithRelations
     */
    'firstName'?: string;
    /**
     * User private status
     * @type {boolean}
     * @memberof UserWithRelations
     */
    'isPrivate'?: boolean;
    /**
     * User profile picture ID
     * @type {string}
     * @memberof UserWithRelations
     */
    'profilePictureId'?: string;
    /**
     * User last name
     * @type {string}
     * @memberof UserWithRelations
     */
    'lastName'?: string;
    /**
     * 
     * @type {Array<Post>}
     * @memberof UserWithRelations
     */
    'posts'?: Array<Post>;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof UserWithRelations
     */
    'comments'?: Array<Comment>;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserWithRelations
     */
    'followed'?: Array<User>;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserWithRelations
     */
    'followers'?: Array<User>;
}
/**
 * 
 * @export
 * @interface UserWithRelationsAndNotifications
 */
export interface UserWithRelationsAndNotifications {
    /**
     * User ID
     * @type {number}
     * @memberof UserWithRelationsAndNotifications
     */
    'id'?: number;
    /**
     * User first name
     * @type {string}
     * @memberof UserWithRelationsAndNotifications
     */
    'firstName'?: string;
    /**
     * User private status
     * @type {boolean}
     * @memberof UserWithRelationsAndNotifications
     */
    'isPrivate'?: boolean;
    /**
     * User profile picture ID
     * @type {string}
     * @memberof UserWithRelationsAndNotifications
     */
    'profilePictureId'?: string;
    /**
     * User last name
     * @type {string}
     * @memberof UserWithRelationsAndNotifications
     */
    'lastName'?: string;
    /**
     * 
     * @type {Array<Post>}
     * @memberof UserWithRelationsAndNotifications
     */
    'posts'?: Array<Post>;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof UserWithRelationsAndNotifications
     */
    'comments'?: Array<Comment>;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserWithRelationsAndNotifications
     */
    'followed'?: Array<User>;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserWithRelationsAndNotifications
     */
    'followers'?: Array<User>;
    /**
     * 
     * @type {Array<Notification>}
     * @memberof UserWithRelationsAndNotifications
     */
    'notifications'?: Array<Notification>;
}
/**
 * 
 * @export
 * @interface UsersMePatchRequest
 */
export interface UsersMePatchRequest {
    /**
     * Whether the user\'s account is private
     * @type {boolean}
     * @memberof UsersMePatchRequest
     */
    'isPrivate'?: boolean;
    /**
     * The ID of the user\'s profile picture
     * @type {string}
     * @memberof UsersMePatchRequest
     */
    'profilePictureId'?: string;
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Log in a user
         * @param {AuthLoginPostRequest} authLoginPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginPost: async (authLoginPostRequest: AuthLoginPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authLoginPostRequest' is not null or undefined
            assertParamExists('authLoginPost', 'authLoginPostRequest', authLoginPostRequest)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authLoginPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log out the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogoutGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign up a new user
         * @param {AuthSignupPostRequest} authSignupPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignupPost: async (authSignupPostRequest: AuthSignupPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authSignupPostRequest' is not null or undefined
            assertParamExists('authSignupPost', 'authSignupPostRequest', authSignupPostRequest)
            const localVarPath = `/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authSignupPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Log in a user
         * @param {AuthLoginPostRequest} authLoginPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLoginPost(authLoginPostRequest: AuthLoginPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthLoginPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLoginPost(authLoginPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Log out the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLogoutGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthLogoutGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLogoutGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authLogoutGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sign up a new user
         * @param {AuthSignupPostRequest} authSignupPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSignupPost(authSignupPostRequest: AuthSignupPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authSignupPost(authSignupPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authSignupPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @summary Log in a user
         * @param {AuthLoginPostRequest} authLoginPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginPost(authLoginPostRequest: AuthLoginPostRequest, options?: any): AxiosPromise<AuthLoginPost200Response> {
            return localVarFp.authLoginPost(authLoginPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log out the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogoutGet(options?: any): AxiosPromise<AuthLogoutGet200Response> {
            return localVarFp.authLogoutGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign up a new user
         * @param {AuthSignupPostRequest} authSignupPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignupPost(authSignupPostRequest: AuthSignupPostRequest, options?: any): AxiosPromise<User> {
            return localVarFp.authSignupPost(authSignupPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Log in a user
     * @param {AuthLoginPostRequest} authLoginPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authLoginPost(authLoginPostRequest: AuthLoginPostRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authLoginPost(authLoginPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log out the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authLogoutGet(options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authLogoutGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign up a new user
     * @param {AuthSignupPostRequest} authSignupPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authSignupPost(authSignupPostRequest: AuthSignupPostRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authSignupPost(authSignupPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PostsApi - axios parameter creator
 * @export
 */
export const PostsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Comment on a post by ID
         * @param {number} id ID of the post
         * @param {CommentPostRequest} commentPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentPost: async (id: number, commentPostRequest: CommentPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('commentPost', 'id', id)
            // verify required parameter 'commentPostRequest' is not null or undefined
            assertParamExists('commentPost', 'commentPostRequest', commentPostRequest)
            const localVarPath = `/posts/{id}/comment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a post
         * @param {CreatePostRequest} createPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPost: async (createPostRequest: CreatePostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPostRequest' is not null or undefined
            assertParamExists('createPost', 'createPostRequest', createPostRequest)
            const localVarPath = `/posts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a post by ID
         * @param {number} id ID of the post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePost', 'id', id)
            const localVarPath = `/posts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all posts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPosts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/posts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get posts of followed users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedPosts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/posts/followed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a post by ID
         * @param {number} id ID of the post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPost', 'id', id)
            const localVarPath = `/posts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Like a post by ID
         * @param {number} id ID of the post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likePost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('likePost', 'id', id)
            const localVarPath = `/posts/{id}/like`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlike a post by ID
         * @param {number} id ID of the post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlikePost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unlikePost', 'id', id)
            const localVarPath = `/posts/{id}/like`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a post by ID
         * @param {number} id ID of the post
         * @param {CreatePostRequest} createPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePost: async (id: number, createPostRequest: CreatePostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePost', 'id', id)
            // verify required parameter 'createPostRequest' is not null or undefined
            assertParamExists('updatePost', 'createPostRequest', createPostRequest)
            const localVarPath = `/posts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostsApi - functional programming interface
 * @export
 */
export const PostsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Comment on a post by ID
         * @param {number} id ID of the post
         * @param {CommentPostRequest} commentPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commentPost(id: number, commentPostRequest: CommentPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commentPost(id, commentPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PostsApi.commentPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a post
         * @param {CreatePostRequest} createPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPost(createPostRequest: CreatePostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Post>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPost(createPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PostsApi.createPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a post by ID
         * @param {number} id ID of the post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PostsApi.deletePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all posts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPosts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Post>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPosts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PostsApi.getAllPosts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get posts of followed users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFollowedPosts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Post>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFollowedPosts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PostsApi.getFollowedPosts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a post by ID
         * @param {number} id ID of the post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Post>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PostsApi.getPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Like a post by ID
         * @param {number} id ID of the post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async likePost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Post>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.likePost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PostsApi.likePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Unlike a post by ID
         * @param {number} id ID of the post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlikePost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Post>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlikePost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PostsApi.unlikePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a post by ID
         * @param {number} id ID of the post
         * @param {CreatePostRequest} createPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePost(id: number, createPostRequest: CreatePostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Post>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePost(id, createPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PostsApi.updatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PostsApi - factory interface
 * @export
 */
export const PostsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostsApiFp(configuration)
    return {
        /**
         * 
         * @summary Comment on a post by ID
         * @param {number} id ID of the post
         * @param {CommentPostRequest} commentPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentPost(id: number, commentPostRequest: CommentPostRequest, options?: any): AxiosPromise<Comment> {
            return localVarFp.commentPost(id, commentPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a post
         * @param {CreatePostRequest} createPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPost(createPostRequest: CreatePostRequest, options?: any): AxiosPromise<Post> {
            return localVarFp.createPost(createPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a post by ID
         * @param {number} id ID of the post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePost(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all posts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPosts(options?: any): AxiosPromise<Array<Post>> {
            return localVarFp.getAllPosts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get posts of followed users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowedPosts(options?: any): AxiosPromise<Array<Post>> {
            return localVarFp.getFollowedPosts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a post by ID
         * @param {number} id ID of the post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPost(id: number, options?: any): AxiosPromise<Post> {
            return localVarFp.getPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Like a post by ID
         * @param {number} id ID of the post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likePost(id: number, options?: any): AxiosPromise<Post> {
            return localVarFp.likePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlike a post by ID
         * @param {number} id ID of the post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlikePost(id: number, options?: any): AxiosPromise<Post> {
            return localVarFp.unlikePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a post by ID
         * @param {number} id ID of the post
         * @param {CreatePostRequest} createPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePost(id: number, createPostRequest: CreatePostRequest, options?: any): AxiosPromise<Post> {
            return localVarFp.updatePost(id, createPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostsApi - object-oriented interface
 * @export
 * @class PostsApi
 * @extends {BaseAPI}
 */
export class PostsApi extends BaseAPI {
    /**
     * 
     * @summary Comment on a post by ID
     * @param {number} id ID of the post
     * @param {CommentPostRequest} commentPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public commentPost(id: number, commentPostRequest: CommentPostRequest, options?: RawAxiosRequestConfig) {
        return PostsApiFp(this.configuration).commentPost(id, commentPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a post
     * @param {CreatePostRequest} createPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public createPost(createPostRequest: CreatePostRequest, options?: RawAxiosRequestConfig) {
        return PostsApiFp(this.configuration).createPost(createPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a post by ID
     * @param {number} id ID of the post
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public deletePost(id: number, options?: RawAxiosRequestConfig) {
        return PostsApiFp(this.configuration).deletePost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all posts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public getAllPosts(options?: RawAxiosRequestConfig) {
        return PostsApiFp(this.configuration).getAllPosts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get posts of followed users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public getFollowedPosts(options?: RawAxiosRequestConfig) {
        return PostsApiFp(this.configuration).getFollowedPosts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a post by ID
     * @param {number} id ID of the post
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public getPost(id: number, options?: RawAxiosRequestConfig) {
        return PostsApiFp(this.configuration).getPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Like a post by ID
     * @param {number} id ID of the post
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public likePost(id: number, options?: RawAxiosRequestConfig) {
        return PostsApiFp(this.configuration).likePost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlike a post by ID
     * @param {number} id ID of the post
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public unlikePost(id: number, options?: RawAxiosRequestConfig) {
        return PostsApiFp(this.configuration).unlikePost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a post by ID
     * @param {number} id ID of the post
     * @param {CreatePostRequest} createPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostsApi
     */
    public updatePost(id: number, createPostRequest: CreatePostRequest, options?: RawAxiosRequestConfig) {
        return PostsApiFp(this.configuration).updatePost(id, createPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Unfollow a user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFollowIdDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersFollowIdDelete', 'id', id)
            const localVarPath = `/users/follow/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Follow a user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFollowIdPost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersFollowIdPost', 'id', id)
            const localVarPath = `/users/follow/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single user
         * @param {number} id ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdGet', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the currently logged in user
         * @param {UsersMePatchRequest} usersMePatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMePatch: async (usersMePatchRequest: UsersMePatchRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersMePatchRequest' is not null or undefined
            assertParamExists('usersMePatch', 'usersMePatchRequest', usersMePatchRequest)
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersMePatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Unfollow a user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersFollowIdDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersFollowIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersFollowIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Follow a user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersFollowIdPost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersFollowIdPost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersFollowIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a single user
         * @param {number} id ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersMeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWithRelationsAndNotifications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersMeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update the currently logged in user
         * @param {UsersMePatchRequest} usersMePatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersMePatch(usersMePatchRequest: UsersMePatchRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWithRelationsAndNotifications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersMePatch(usersMePatchRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersMePatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Unfollow a user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFollowIdDelete(id: number, options?: any): AxiosPromise<UserWithRelations> {
            return localVarFp.usersFollowIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Follow a user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFollowIdPost(id: number, options?: any): AxiosPromise<UserWithRelations> {
            return localVarFp.usersFollowIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.usersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single user
         * @param {number} id ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdGet(id: number, options?: any): AxiosPromise<UserWithRelations> {
            return localVarFp.usersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMeGet(options?: any): AxiosPromise<UserWithRelationsAndNotifications> {
            return localVarFp.usersMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the currently logged in user
         * @param {UsersMePatchRequest} usersMePatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMePatch(usersMePatchRequest: UsersMePatchRequest, options?: any): AxiosPromise<UserWithRelationsAndNotifications> {
            return localVarFp.usersMePatch(usersMePatchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Unfollow a user
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersFollowIdDelete(id: number, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersFollowIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Follow a user
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersFollowIdPost(id: number, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersFollowIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGet(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single user
     * @param {number} id ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdGet(id: number, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the currently logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersMeGet(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the currently logged in user
     * @param {UsersMePatchRequest} usersMePatchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersMePatch(usersMePatchRequest: UsersMePatchRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersMePatch(usersMePatchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



